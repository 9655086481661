import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonModal,
  IonAlert,
  IonToast,
  IonText,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import "./FishEditModal.css";
import "../shared/style.css";

import {
  addCircleOutline,
  arrowBack,
  heart,
  removeCircleOutline,
  trash,
} from "ionicons/icons";

const FishEditModal: React.FC<any> = ({
  showFishModal,
  setShowFishModal,
  fishModalData,
  setFishModalData,
  storedTankEntries,
  lang,
}) => {
  let fishQuantity: any = 0;
  let fishLength: any = 0;
  let fishBodyShape: any = "";
  let fishName: any = "";
  let fishIdentification = "";
  let saveType = "create";
  let tankIdentification = "";
  let tankName = "";
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showValidationToast, setShowValidationToast] = useState(false);
  const [isFormAlreadySent, setIsFormAlreadySent] = useState(false);

  const [formValueBuffer, setFormValueBuffer] = useState({
    fishName: "",
    fishBodyShape: "",
    fishQuantity: 0,
    fishLength: 0,
    tankIdentification: "",
    fishIdentification: "",
  });

  if (fishModalData.fishIdentification !== "" && isFormAlreadySent === false) {
    fishQuantity = fishModalData.fishQuantity;
    fishLength = fishModalData.fishLength;
    fishBodyShape = fishModalData.fishBodyShape;
    fishName = fishModalData.fishName;
    fishIdentification = fishModalData.fishIdentification;
    tankIdentification = fishModalData.tankIdentification;
    tankName = fishModalData.tankName;
  }

  if (isFormAlreadySent === true) {
    fishQuantity = formValueBuffer.fishQuantity;
    fishLength = formValueBuffer.fishLength;
    fishBodyShape = formValueBuffer.fishBodyShape;
    fishName = formValueBuffer.fishName;
    tankIdentification = formValueBuffer.tankIdentification;
    fishIdentification = formValueBuffer.fishIdentification;
  }

  function switchSaveType() {
    saveType = "delete";

    if (fishModalData.saveType === "to-create") {
      saveType = "create";
    }
    if (fishModalData.saveType === "to-update") {
      saveType = "update";
    }
  }

  function checkFormAndSetData() {
    if (
      fishName !== "" &&
      fishName !== undefined &&
      fishQuantity !== 0 &&
      fishQuantity !== undefined &&
      fishQuantity !== "" &&
      fishLength !== 0 &&
      fishLength !== undefined &&
      fishLength !== "" &&
      fishBodyShape !== "" &&
      fishBodyShape !== undefined &&
      tankIdentification !== "" &&
      tankIdentification !== undefined
    ) {
      setFishModalData({
        fishQuantity: fishQuantity,
        fishLength: fishLength,
        fishBodyShape: fishBodyShape,
        fishName: fishName,
        fishIdentification: fishIdentification,
        saveType: saveType,
        tankIdentification: tankIdentification,
        tankName: tankName,
      });
      setShowFishModal(false);
      setIsFormAlreadySent(false);
    } else {
      setShowValidationToast(true);
    }
  }

  useEffect(() => {
    switchSaveType();
  });

  return (
    <IonPage>
      <IonModal
        isOpen={showFishModal}
        mode="ios"
        cssClass="fish-modal"
        onDidDismiss={() => setShowFishModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonIcon
              icon={arrowBack}
              slot="start"
              className="toolbar-icons"
              onClick={() => {
                setShowFishModal(false);
              }}
            />
            <IonTitle>
              {fishIdentification === undefined
                ? lang.text_myfish_fish_modal_create_headline
                : lang.text_myfish_fish_modal_edit_headline}
            </IonTitle>
            <IonAlert
              isOpen={showDeleteAlert}
              onDidDismiss={() => setShowDeleteAlert(false)}
              header={lang.text_myfish_fish_modal_edit_delete_alert_headline}
              message={
                lang.text_myfish_fish_modal_edit_delete_alert_description
              }
              buttons={[
                {
                  text: lang.text_myfish_fish_modal_edit_delete_alert_button_abort,
                  role: "cancel",
                },
                {
                  text: lang.text_myfish_fish_modal_edit_delete_alert_button_confirm,
                  handler: () => {
                    setFishModalData({
                      fishIdentification: fishModalData.fishIdentification,
                      saveType: "delete",
                    });
                    setShowFishModal(false);
                  },
                },
              ]}
            />
            {fishIdentification !== undefined ? (
              <IonIcon
                icon={trash}
                slot="end"
                className="toolbar-icons"
                onClick={() => {
                  setFishModalData({
                    fishIdentification: fishModalData.fishIdentification,
                    tankIdentification: fishModalData.tankIdentification,
                    saveType: "delete",
                  });
                  setShowFishModal(false);
                }}
              />
            ) : null}
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonGrid fixed>
            <IonRow className="ion-margin-bottom">
              <IonCol size="1"></IonCol>
              <IonCol size="10" className="fishModal-row ion-text-center">
                <IonLabel className="fishModal-label">
                  {lang.text_myfish_fish_modal_pond_headline}
                </IonLabel>
                <IonInput
                  id="fishname"
                  placeholder={lang.text_myfish_fish_modal_pond_placeholder}
                  value={fishName}
                  maxlength={20}
                  clearInput={true}
                  onIonChange={(e) => (fishName = e.detail.value)}
                />
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="1"></IonCol>
              <IonCol size="10" className="fishModal-row ion-text-center">
                <IonLabel className="fishModal-label">
                  {lang.text_myfish_fish_modal_pond_select_headline}
                </IonLabel>
                <IonSelect
                  okText={lang.text_myfish_fish_modal_select_button_confirm}
                  cancelText={lang.text_myfish_fish_modal_select_button_abort}
                  value={
                    tankIdentification === undefined &&
                    storedTankEntries.length > 0
                      ? (tankIdentification = storedTankEntries[0].entryId)
                      : tankIdentification
                  }
                  placeholder={tankName}
                  onIonChange={(e) => (tankIdentification = e.detail.value)}>
                  {storedTankEntries?.map((tank, tankIndex) => {
                    return (
                      <IonSelectOption key={tankIndex} value={tank.entryId}>
                        {tank.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <IonRow className="ion-margin-bottom">
              <IonCol size="1"></IonCol>
              <IonCol size="10" className="ion-text-center fishModal-row">
                <IonLabel className="fishModal-label">
                  {lang.text_myfish_fish_modal_quantity_headline}
                </IonLabel>
                <IonInput
                  id="fishquantity"
                  type="number"
                  placeholder="0"
                  inputmode="numeric"
                  clearInput={true}
                  value={fishQuantity !== 0 ? fishQuantity : null}
                  onIonChange={(e) => (fishQuantity = e.detail.value)}
                />
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <IonRow className="ion-margin-bottom ">
              <IonCol size="1"></IonCol>
              <IonCol className="ion-text-center fishModal-row">
                <IonLabel className="fishModal-label">
                  {lang.text_myfish_fish_modal_length_headline}
                </IonLabel>
                <IonInput
                  type="number"
                  inputmode="numeric"
                  placeholder="cm"
                  clearInput={true}
                  value={fishLength !== 0 ? fishLength : null}
                  onIonChange={(e) => (fishLength = e.detail.value)}
                />
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="1"></IonCol>

              <IonCol size="10" className="ion-text-center">
                <IonLabel className="bodyShape-label ion-margin-bottom">
                  {lang.text_myfish_fish_modal_body_shape_headline}
                </IonLabel>
                <IonSegment
                  mode="md"
                  value={fishBodyShape}
                  onIonChange={(e) =>
                    (fishBodyShape = e.detail.value as string)
                  }>
                  <IonSegmentButton value="slim">
                    <IonIcon icon={removeCircleOutline}></IonIcon>
                    <IonLabel>
                      {lang.text_myfish_fish_modal_body_shape_type1}
                    </IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="regular" color="secondary">
                    <IonIcon icon={heart}></IonIcon>
                    <IonLabel>
                      {lang.text_myfish_fish_modal_body_shape_type2}
                    </IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="fat">
                    <IonIcon icon={addCircleOutline}></IonIcon>
                    <IonLabel>
                      {lang.text_myfish_fish_modal_body_shape_type3}
                    </IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="1"></IonCol>
              <IonCol size="3">
                <IonText className="bodyShape-title">
                  {lang.text_myfish_fish_modal_body_shape_type1}:
                </IonText>
              </IonCol>
              <IonCol size="7">
                <IonText className="bodyShape-description">
                  {lang.text_myfish_fish_modal_body_shape_type1_description}
                </IonText>
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="1"></IonCol>
              <IonCol size="3">
                <IonText className="bodyShape-title">
                  {lang.text_myfish_fish_modal_body_shape_type2}:
                </IonText>
              </IonCol>
              <IonCol size="7">
                <IonText className="bodyShape-description">
                  {lang.text_myfish_fish_modal_body_shape_type2_description}
                </IonText>
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="1"></IonCol>
              <IonCol size="3">
                <IonText className="bodyShape-title">
                  {lang.text_myfish_fish_modal_body_shape_type3}:
                </IonText>
              </IonCol>
              <IonCol size="7">
                <IonText className="bodyShape-description">
                  {lang.text_myfish_fish_modal_body_shape_type3_description}
                </IonText>
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton
                  className="saveButton"
                  expand="block"
                  onClick={() => {
                    setFormValueBuffer({
                      fishName: fishName,
                      fishBodyShape: fishBodyShape,
                      fishQuantity: fishQuantity,
                      fishLength: fishLength,
                      tankIdentification: tankIdentification,
                      fishIdentification: fishModalData.fishIdentification,
                    });
                    setIsFormAlreadySent(true);
                    checkFormAndSetData();
                  }}>
                  {lang.text_myfish_fish_modal_create_button_save}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonToast
              isOpen={showValidationToast}
              mode="ios"
              message={lang.text_myfish_modal_validation_toast}
              cssClass="ion-text-center"
              duration={1000}
              color="danger"
              onDidDismiss={() => setShowValidationToast(false)}
            />
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default FishEditModal;
