import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";

const PrivacyModal: React.FC<any> = ({
  showPrivacyModal,
  setShowPrivacyModal,
}) => {
  return (
    <IonPage>
      <IonModal
        isOpen={showPrivacyModal}
        mode="ios"
        cssClass="more-modal"
        onDidDismiss={() => setShowPrivacyModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  setShowPrivacyModal(false);
                }}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>Polityka prywatności</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <h1>Polityka prywatności</h1>
                  </IonCardHeader>
                  <IonCardContent>
                    <h2>
                      Polityka Prywatności dla kalkulator.koisklep.pl
                      <br /> Koi Centrum Łukasz Gładki właściciela serwisu
                      <br /> www.koisklep.pl
                    </h2>
                    <br />
                    <h2>§1. Informacje ogólne</h2>
                    <p>
                      1. Administratorem danych jest KOI CENTRUM Łukasz Gładki z
                      siedzibą w Brynicy przy ul. Łubniańska 19b, wpisanego do
                      Rejestru Przedsiębiorców Centralnej Ewidencji i Informacji
                      o Działalności Gospodarczej (CEIDG) prowadzonej przez
                      ministra właściwego ds. gospodarki, NIP 99102354889 REGON
                      : 160392987
                      <br />
                      <br />
                      Ochrona danych odbywa się zgodnie z wymogami powszechnie
                      obowiązujących przepisów prawa, a ich przechowywanie ma
                      miejsce na zabezpieczonych serwerach.
                    </p>
                    <br />
                    <p>
                      2. Dla interpretacji terminów stosuje się słowniczek
                      Regulaminu lub tak jak zostało to opisane w Polityce
                      Prywatności (jeżeli wynika to bezpośrednio z opisu).
                    </p>

                    <br />
                    <p>
                      3. Na potrzeby lepszego odbioru Polityki Prywatności
                      termin „Użytkownik” zastąpiony został określeniem „Ty”,
                      „Administrator” – „My”. Termin „RODO” oznacza
                      Rozporządzenie Parlamentu Europejskiego i Rady (UE)
                      2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                      fizycznych w związku z przetwarzaniem danych osobowych i w
                      sprawie swobodnego przepływu takich danych oraz uchylenia
                      dyrektywy 95/46/WE.
                    </p>
                    <br />
                    <p>
                      4. Szanujemy prawo do prywatności i dbamy o bezpieczeństwo
                      danych. W tym celu używany jest m.in. bezpieczny protokół
                      szyfrowania komunikacji (SSL). 5. Dane osobowe podawane w
                      formularzu na landing page’u są traktowane jako poufne i
                      nie są widoczne dla osób nieuprawnionych.
                    </p>
                    <br />
                    <h2>§2. Administrator Danych</h2>
                    <br />
                    <p>
                      1. Usługodawca jest administratorem danych swoich
                      klientów.
                    </p>
                    <br />
                    <p>
                      2. Dane osobowe przetwarzane są: a. zgodnie z przepisami
                      dotyczącymi ochrony danych osobowych, b. zgodnie z
                      wdrożoną Polityką Prywatności, c. w zakresie i celu
                      niezbędnym do nawiązania, ukształtowania treści Umowy,
                      zmiany bądź jej rozwiązania oraz prawidłowej realizacji
                      Usług świadczonych drogą elektroniczną, d. w zakresie i
                      celu niezbędnym do wypełnienia uzasadnionych interesów
                      (prawnie usprawiedliwionych celów), a przetwarzanie nie
                      narusza praw i wolności osoby, której dane dotyczą:
                    </p>
                    <br />
                    <p>
                      3. Każda osoba, której dane dotyczą (jeżeli jesteśmy ich
                      administratorem) ma prawo dostępu do danych, sprostowania,
                      usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu,
                      prawo wniesienia skargi do organu nadzorczego.
                    </p>
                    <br />
                    <p>
                      4. Kontakt z osobą nadzorującą przetwarzanie danych
                      osobowych w organizacji Usługodawcy jest możliwy drogą
                      elektroniczną pod adresem e-mail: info@koisklep.pl.
                    </p>
                    <br />
                    <p>
                      5. Zastrzegamy sobie prawo do przetwarzania Twoich danych
                      po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie
                      na potrzeby dochodzenia ewentualnych roszczeń przed sądem
                      lub jeżeli przepisy krajowe albo unijne bądź prawa
                      międzynarodowego obligują nas do retencji danych.
                    </p>
                    <br />
                    <p>
                      6. Usługodawca ma prawo udostępniać dane osobowe
                      Użytkownika oraz innych jego danych podmiotom upoważnionym
                      na podstawie właściwych przepisów prawa (np. organom
                      ścigania).
                    </p>
                    <br />
                    <p>
                      7. Usunięcie danych osobowych może nastąpić na skutek
                      cofnięcia zgody bądź wniesienia prawnie dopuszczalnego
                      sprzeciwu na przetwarzanie danych osobowych.
                    </p>
                    <br />
                    <p>
                      8. Usługodawca nie udostępniania danych osobowych innym
                      podmiotom aniżeli upoważnionym na podstawie właściwych
                      przepisów prawa.
                    </p>
                    <br />
                    <h2>§3. Pliki cookies</h2>
                    <br />
                    <p>
                      1. Witryna www.koisklep.pl oraz kalkulator.koisklep.pl
                      używa cookies. Są to niewielkie pliki tekstowe wysyłane
                      przez serwer www i przechowywane przez oprogramowanie
                      komputera przeglądarki. Kiedy przeglądarka ponownie
                      połączy się ze stroną, witryna rozpoznaje rodzaj
                      urządzenia, z którego łączy się użytkownik. Parametry
                      pozwalają na odczytanie informacji w nich zawartych
                      jedynie serwerowi, który je utworzył. Cookies ułatwiają
                      więc korzystanie z wcześniej odwiedzonych witryn.
                      Gromadzone informacje dotyczą adresu IP, typu
                      wykorzystywanej przeglądarki, języka, rodzaju systemu
                      operacyjnego, dostawcy usług internetowych, informacji o
                      czasie i dacie, lokalizacji oraz informacji przesyłanych
                      do witryny za pośrednictwem formularza kontaktowego.
                    </p>
                    <br />
                    <p>
                      1. Na naszej witrynie oraz kalkulatorze wykorzystujemy
                      następujące pliki cookies: Cookies sesyjne: są
                      przechowywane na Urządzeniu Użytkownika i pozostają tam do
                      momentu zakończenia sesji danej przeglądarki. Zapisane
                      informacje są wówczas trwale usuwane z pamięci Urządzenia.
                      Mechanizm cookies sesyjnych nie pozwala na pobieranie
                      jakichkolwiek danych osobowych ani żadnych informacji
                      poufnych z Urządzenia Użytkownika. Cookies trwałe: są
                      przechowywane na Urządzeniu Użytkownika i pozostają tam do
                      momentu ich skasowania. Zakończenie sesji danej
                      przeglądarki lub wyłączenie Urządzenia nie powoduje ich
                      usunięcia z Urządzenia Użytkownika. Mechanizm cookies
                      trwałych nie pozwala na pobieranie jakichkolwiek danych
                      osobowych ani żadnych informacji poufnych z Urządzenia
                      Użytkownika.
                    </p>
                    <br />
                    <p>
                      Administrator wykorzystuje Cookies Własne w następujących
                      celach:
                      <ol type="a">
                        <li>
                          Konfiguracji serwisu
                          <ul>
                            <li>
                              rozpoznania urządzenia Użytkownika Serwisu oraz
                              jego lokalizację i odpowiednio wyświetlenia strony
                              internetowej, dostosowanej do jego indywidualnych
                              potrzeb;
                            </li>
                            <li>
                              rozmiaru czcionki, wyglądu strony internetowej
                              itp...
                            </li>
                          </ul>
                          <li>
                            Realizacji procesów niezbędnych dla pełnej
                            funkcjonalności stron internetowych oraz
                            kalkulatora.
                            <ul>
                              <li>
                                dostosowania zawartości stron internetowych
                                Serwisu do preferencji Użytkownika oraz
                                optymalizacji korzystania ze stron internetowych
                                Serwisu. W szczególności pliki te pozwalają
                                rozpoznać podstawowe parametry Urządzenia
                                Użytkownika i odpowiednio wyświetlić stronę
                                internetową, dostosowaną do jego indywidualnych
                                potrzeb;
                              </li>
                            </ul>
                          </li>
                        </li>
                        <li>
                          Analiz i badań oraz audytu oglądalności
                          <ul>
                            <li>
                              tworzenia anonimowych statystyk, które pomagają
                              zrozumieć, w jaki sposób Użytkownicy Serwisu
                              korzystają ze stron internetowych Serwisu, co
                              umożliwia ulepszanie ich struktury i zawartości;
                            </li>
                          </ul>
                        </li>
                        <li>
                          Zapewnienia bezpieczeństwa i niezawodności serwisu
                          Administrator usługi wykorzystuje Cookies Zewnętrzne w
                          następujących celach:
                        </li>
                        <li>
                          zbierania ogólnych i anonimowych danych statycznych za
                          pośrednictwem narzędzi analitycznych Google Analytics
                          [administrator cookies: Google Inc z siedzibą w USA].
                        </li>
                      </ol>
                    </p>
                    <br />
                    <p>
                      2. Możliwości określenia warunków przechowywania lub
                      uzyskiwania dostępu przez Cookies:
                      <br /> − Użytkownik może samodzielnie i w każdym czasie
                      zmienić ustawienia dotyczące plików Cookies, określając
                      warunki ich przechowywania i uzyskiwania dostępu przez
                      pliki Cookies do Urządzenia Użytkownika. Zmiany ustawień,
                      o których mowa w zdaniu poprzednim, Użytkownik może
                      dokonać za pomocą ustawień przeglądarki internetowej lub
                      za pomocą konfiguracji usługi. Ustawienia te mogą zostać
                      zmienione w szczególności w taki sposób, aby blokować
                      automatyczną obsługę plików cookies w ustawieniach
                      przeglądarki internetowej bądź informować o ich
                      każdorazowym zamieszczeniu Cookies na urządzeniu
                      Użytkownika. Szczegółowe informacje o możliwości i
                      sposobach obsługi plików cookies dostępne są w
                      ustawieniach oprogramowania (przeglądarki internetowej).
                      <br /> − Użytkownik może w każdej chwili usunąć pliki
                      Cookies korzystając z dostępnych funkcji w przeglądarce
                      internetowej, której używa.
                      <br /> − Ograniczenie stosowania plików Cookies, może
                      wpłynąć na niektóre funkcjonalności dostępne na stronie
                      internetowej Serwisu.
                    </p>
                    <br />
                    <h2>§4. Cele i podstawy przetwarzania</h2>
                    <p>
                      Określono cele przetwarzania Państwa danych. Jako
                      Administrator będziemy przetwarzać Państwa dane w celu:
                      <ol>
                        <li>
                          przyjęcia zamówienia, zawarcia i realizacji umowy
                          (świadczenia usług), marketingu bezpośredniego
                          oferowanych usług,
                        </li>
                        <li>
                          naszego prawnie uzasadnionego interesu, polegającego
                          na przetwarzaniu danych w celu ustalenia, dochodzenia
                          lub obrony ewentualnych roszczeń (art. 6 ust. 1 lit. f
                          RODO),
                        </li>
                        <li>
                          prowadzenia wszelkich niezbędnych działań związanych z
                          pełnym zagwarantowaniem bezpieczeństwa,
                        </li>
                        <li>
                          archiwizacji danych dotyczących zawartych umów,
                          złożonych zamówień oraz zgłaszanych reklamacji,
                        </li>
                        <li>
                          przesyłania odpowiedzi na zgłaszane uwagi, pisma,
                          wnioski, zastrzeżenia, reklamacje,
                        </li>
                        <li>
                          realizowania danych potrzebnych do korzystania z
                          kalkulator.koisklep.pl
                        </li>
                        <li>
                          realizowania obowiązków prawnych podmiotu KOI CENTRUM
                          Łukasz Gładki
                        </li>
                      </ol>
                    </p>
                    <br />
                    <h2>§5. Informacje o formularzach</h2>
                    <p>
                      <ol>
                        <li>
                          Serwis zbiera informacje podane dobrowolnie przez
                          użytkownika.
                        </li>
                        <li>
                          Serwis może zapisać ponadto informacje o parametrach
                          połączenia (oznaczenie czasu, adres IP).
                        </li>
                        <li>
                          Dane z formularzy zapisów są wyświetlane na stronie
                          www.koisklep.pl lub kalkulator.koisklep.pl
                        </li>
                        <li>
                          Dane osobowych podane w formularzu zapisów są
                          rejestrowane przez Administratora strony w rejestrze
                          (RODO)
                        </li>
                        <li>
                          Osoba, która wypełni formularz zapisów ma prawo do
                          wglądu, modyfikacji i żądania zaprzestania
                          przetwarzania swoich danych w dowolnym momencie
                        </li>
                        <li>
                          Dane podane w formularzu są przetwarzane w celu
                          utworzenia list zawodników biorących udział w zawodach
                          sportowych oraz list z wynikami po zawodach.
                        </li>
                      </ol>
                    </p>
                    <br />
                    <h2>§6. Prawo do sprzeciwu</h2>
                    <p>
                      <ol>
                        <li>
                          W każdej chwili przysługuje Państwu prawo do
                          wniesienia sprzeciwu wobec przetwarzania Państwa
                          danych opisanych powyżej. Przestaniemy przetwarzać
                          Państwa dane w tych celach, chyba że będziemy w stanie
                          wykazać, że w stosunku do Państwa danych istnieją dla
                          nas ważne prawnie uzasadnione podstawy, które są
                          nadrzędne wobec Państwa interesów, praw i wolności lub
                          Państwa dane będą nam niezbędne do ewentualnego
                          ustalenia, dochodzenia lub obrony roszczeń.
                        </li>
                        <li>
                          W każdej chwili przysługuje Państwu prawo do
                          wniesienia sprzeciwu wobec przetwarzania Twoich danych
                          w celu prowadzenia marketingu bezpośredniego. Jeżeli
                          skorzystasz z tego prawa – zaprzestaniemy
                          przetwarzania danych w tym celu.
                        </li>
                        <li>
                          Przetwarzanie danych do momentu cofnięcia przez Ciebie
                          zgody pozostaje zgodne z prawem.
                        </li>
                      </ol>
                    </p>
                    <br />
                    <h2>§7. Okres przechowywania danych</h2>
                    <p>
                      Państwa dane osobowe wynikające z zawartych umów lub
                      przesłanych pism będą przetwarzane przez:
                      <ol>
                        <li>okres przez jaki są świadczone usługi;</li>
                        <li>
                          okres, który jest niezbędny do obrony interesów
                          Administratora;
                        </li>
                        <li>
                          okres na jaki została udzielona zgoda; do
                          przetwarzania danych przez określony czas (np. ustawa
                          o rachunkowości);
                        </li>
                        <li>
                          zostanie przyjęty Twój sprzeciw wobec przetwarzania
                          Twoich danych osobowych – w przypadku gdy podstawą
                          przetwarzania Twoich danych był uzasadniony interes
                          Administratora lub gdy dane były przetwarzane w celu
                          marketingu bezpośredniego, w tym profilowania.
                        </li>
                      </ol>
                    </p>
                    <br />
                    <h2>§10. Informacja o dobrowolności podania danych</h2>
                    <p>
                      Podanie danych ma charakter dobrowolny, niemniej jest
                      niezbędne do zawarcia i realizacji umowy przez zakupy na
                      stronie www.koisklep.pl lub korzystania z kalkulatora na
                      stronie www.kalkulator.koisklep.pl
                    </p>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default PrivacyModal;
