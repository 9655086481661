import TankEntry from "./TankEntry";
import { plainToClass } from "class-transformer";

async function setInitialValues(store) {
  await store.set("isFirstStart", false);

  let storedTankEntries: Array<TankEntry> = [];
  let initialTank = new TankEntry("Staw 1");

  storedTankEntries.push(initialTank);
  await store.set("tanks", JSON.stringify(storedTankEntries));
  await store.set("feedType", "koi360");
  await store.set("growthGoal", "regular-growth");
  await store.set("waterTemperature", 15);
}

function loadFeedTypeFromStorage(store, setFeedType) {
  store.get("feedType").then((feed) => {
    setFeedType(feed);
  });
}

function loadGrowthGoalFromStorage(store, setGrowthGoal) {
  store.get("growthGoal").then((goal) => {
    setGrowthGoal(goal);
  });
}

function loadWaterTemperatureFromStorage(store, setWaterTemperature) {
  store.get("waterTemperature").then((waterTemperature) => {
    setWaterTemperature(waterTemperature);
  });
}

async function saveFeedTypeToStorage(store, feedType) {
  await store.set("feedType", feedType);
}

async function saveGrowthGoalToStorage(store, growthGoal) {
  await store.set("growthGoal", growthGoal);
}

async function saveWaterTemperatureToStorage(store, waterTemperature) {
  await store.set("waterTemperature", waterTemperature);
}

function saveTankArrayToStorage(
  store,
  localStoredTankEntries,
  setStoredTankEntries
) {
  store.set("tanks", JSON.stringify(localStoredTankEntries)).then(() => {
    loadTankArrayFromStorage(store, setStoredTankEntries);
  });
}

function loadTankArrayFromStorage(store, setStoredTankEntries) {
  store.get("tanks").then((tankEntries) => {
    let localStoredTankEntries = JSON.parse(tankEntries);
    localStoredTankEntries = plainToClass(TankEntry, localStoredTankEntries);
    setStoredTankEntries(localStoredTankEntries);
  });
}

function findAndGetTank(tankId, localStoredTankEntries) {
  let entryIndex = localStoredTankEntries.findIndex(
    (x) => x.entryId === tankId
  );
  let foundTank = localStoredTankEntries[entryIndex];
  return foundTank;
}

function getTankIndex(tankId, localStoredTankEntries) {
  let entryIndex = localStoredTankEntries.findIndex(
    (x) => x.entryId === tankId
  );
  return entryIndex;
}

function deleteTank(
  store,
  tankId,
  localStoredTankEntries,
  setStoredTankEntries
) {
  let tankIndex = getTankIndex(tankId, localStoredTankEntries);
  localStoredTankEntries.splice(tankIndex, 1);
  saveTankArrayToStorage(store, localStoredTankEntries, setStoredTankEntries);
}

export {
  setInitialValues,
  saveTankArrayToStorage,
  findAndGetTank,
  loadTankArrayFromStorage,
  deleteTank,
  loadFeedTypeFromStorage,
  loadGrowthGoalFromStorage,
  loadWaterTemperatureFromStorage,
  saveFeedTypeToStorage,
  saveGrowthGoalToStorage,
  saveWaterTemperatureToStorage,
};
