import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonLabel,
  IonToast,
  IonModal,
  IonAlert,
} from "@ionic/react";
import "./TankModal.css";
import { arrowBack, trash } from "ionicons/icons";

const TankEditModal: React.FC<any> = ({
  showTankModal,
  setShowTankModal,
  fishModalData,
  setFishModalData,
  lang,
}) => {
  let tankName: any = "";
  let tankIdentification = "";
  let saveType = "create-tank";

  const [showValidationToast, setShowValidationToast] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  if (fishModalData.tankName !== "") {
    tankIdentification = fishModalData.tankIdentification;
    tankName = fishModalData.tankName;
    saveType = "update-tank";
  }

  function checkFormAndSetData() {
    if (tankName !== "" && tankName !== undefined) {
      setFishModalData({
        saveType: saveType,
        tankIdentification: tankIdentification,
        tankName: tankName,
      });
      setShowTankModal(false);
    } else {
      setShowValidationToast(true);
    }
  }

  function switchSaveType() {
    saveType = "delete";

    if (fishModalData.saveType === "to-create-tank") {
      saveType = "create-tank";
    }
    if (fishModalData.saveType === "to-update-tank") {
      saveType = "update-tank";
    }
  }

  useEffect(() => {
    switchSaveType();
  });

  return (
    <IonPage>
      <IonModal
        isOpen={showTankModal}
        mode="ios"
        cssClass="tank-modal"
        onDidDismiss={() => setShowTankModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  setShowTankModal(false);
                }}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {fishModalData.saveType === "to-create-tank"
                ? lang.text_myfish_pond_modal_create_headline
                : lang.text_myfish_pond_modal_edit_headline}
            </IonTitle>
            <IonAlert
              isOpen={showDeleteAlert}
              onDidDismiss={() => setShowDeleteAlert(false)}
              header={lang.text_myfish_pond_modal_edit_delete_alert_headline}
              message={
                lang.text_myfish_pond_modal_edit_delete_alert_description
              }
              buttons={[
                {
                  text: lang.text_myfish_pond_modal_edit_delete_alert_button_abort,
                  role: "cancel",
                },
                {
                  text: lang.text_myfish_pond_modal_edit_delete_alert_button_confirm,
                  handler: () => {
                    setFishModalData({
                      tankIdentification: fishModalData.tankIdentification,
                      saveType: "delete-tank",
                    });
                    setShowTankModal(false);
                  },
                },
              ]}
            />
            {fishModalData.saveType === "to-update-tank" ? (
              <IonIcon
                icon={trash}
                slot="end"
                className="toolbar-icons"
                onClick={() => {
                  setShowDeleteAlert(true);
                }}
              />
            ) : null}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow className="ion-margin-bottom">
              <IonCol size="1"></IonCol>
              <IonCol size="10" className="fishModal-row ion-text-center">
                <IonLabel className="fishModal-label">
                  {lang.text_myfish_pond_modal_pond_name_headline}
                </IonLabel>
                <IonInput
                  type="text"
                  placeholder={
                    lang.text_myfish_pond_modal_pond_name_placeholder
                  }
                  value={tankName}
                  maxlength={20}
                  clearInput={true}
                  onIonChange={(e) => {
                    tankName = e.detail.value;
                  }}
                />
              </IonCol>
              <IonCol size="1"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton
                  expand="block"
                  onClick={() => {
                    checkFormAndSetData();
                  }}>
                  {lang.text_myfish_pond_modal_create_button_save}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonToast
              isOpen={showValidationToast}
              mode="ios"
              message={lang.text_myfish_modal_validation_toast}
              cssClass="ion-text-center"
              duration={3000}
              color="danger"
              onDidDismiss={() => setShowValidationToast(false)}
            />
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default TankEditModal;
