import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import parse from "html-react-parser";

const HelpModal: React.FC<any> = ({
  showHelpModal,
  setShowHelpModal,
  lang,
}) => {
  return (
    <IonPage>
      <IonModal
        isOpen={showHelpModal}
        mode="ios"
        cssClass="more-modal"
        onDidDismiss={() => setShowHelpModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  setShowHelpModal(false);
                }}>
                <IonIcon icon={arrowBack}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>{lang.text_more_help_title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <h5>
                      {lang.text_more_help_modal_calc_instruction_headline}
                    </h5>
                  </IonCardHeader>
                  <IonCardContent>
                    {parse(
                      lang.text_more_help_modal_calc_instruction_description
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <h5>{lang.text_more_help_modal_save_data_headline}</h5>
                  </IonCardHeader>
                  <IonCardContent>
                    {parse(lang.text_more_help_modal_save_data_description)}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <h5>{lang.text_more_help_modal_contact_headline}</h5>
                  </IonCardHeader>
                  <IonCardContent>
                    {parse(lang.text_more_help_modal_contact_description)}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default HelpModal;
