// Polish language: Koisklep content
export const lang = {
  text_menubar_fish: "Ryby",
  text_menubar_calc: "Kalkulator",
  text_menubar_shop: "Sklep",
  text_menubar_more: "Więcej",
  text_calc_page_title: "Kalkulator pokarmu",
  text_calc_feed_type_headline: "KOI SKLEP - rodzaje pokarmów",
  text_calc_feed_type_name1: "Koisklep Silver",
  text_calc_feed_type_name2: "Koisklep Gold",
  text_calc_feed_type_name3: "",
  text_calc_fish_stock_headline: "Obsada ryb",
  text_calc_fish_stock_type1: "Obsada<br/>mieszane<br/>/ małe koi",
  text_calc_fish_stock_type1_description:
    "Polecane dla obsady mieszanej składającej się z dużej ilości mniejszych karpi i małych ilosci dużych karpi koi.",
  text_calc_fish_stock_type2: "Obsada<br/>mieszane<br/>duże koi",
  text_calc_fish_stock_type2_description:
    "Polecane dla zasobów z większymi karpiami Koi powyżej 50 cm (idealna ilość pokarmu dla zdrowego wzrostu i zachowania pięknego koloru).",
  text_calc_fish_stock_type3: "Hodowla<br/>młodych<br/>koi",
  text_calc_fish_stock_type3_description:
    "Polecane tylko dla grup młodych Koi Tosai i Nisai w podobnym wieku (idealne do hodowli koi w zbiornikach lub kwarantannach domowych).",
  text_calc_water_temperature_headline: "Temperatura wody",
  text_calc_calculation_button: "Oblicz ilość pokarmu",
  text_calc_modal_headline: "Zalecenia żywieniowe",
  text_calc_modal_disclaimer_headline: "Wskazówka",
  text_calc_modal_disclaimer_description:
    "Kalkulator pokarmu został stworzony zgodnie z naszą najlepszą wiedzą i wymogami. Wszystkie informacje są bez gwarancji. Zawsze zwracaj uwagę na akceptację pokarmu u swoich koi.",
  text_calc_modal_recommendation_feeding_number_never:
    "Przy tych warunkach karmienie nie jest zalecane",
  text_calc_modal_recommendation_feeding_number_7_14_days:
    "Zgodnie z Państwa informacjami zalecamy karmienie co 7 do 14 dni, w zależności od akceptacji ryb",
  text_calc_modal_recommendation_feeding_number_2_4_days:
    "Zgodnie z Państwa informacjami zalecamy karmienie co 2 do 4 dni, w zależności od akceptacji ryb",
  text_calc_modal_recommendation_feeding_number_1_2_per_day:
    "Zgodnie z Państwa informacjami, zalecamy karmić łącznie 1 do 2 razy dziennie",
  text_calc_modal_recommendation_feeding_number_2_3_per_day:
    "Zgodnie z Państwa informacjami, zalecamy karmić łacznie 2 do 3 razy dziennie",
  text_calc_modal_recommendation_feeding_number_4_5_per_day:
    "Zgodnie z Państwa informacjami, zalecamy karmić łącznie 4 do 5 razy dziennie",
  text_calc_modal_recommendation_feeding_number_3_7_days:
    "Zgodnie z Państwa informacjami zalecamy karmienie co 3 do 7 dni, w zależności od akceptacji",
  text_calc_modal_recommendation_feeding_number_1_per_day:
    "Zgodnie z Państwa informacjami zalecamy karmić 1 dziennie",
  text_calc_modal_recommendation_feeding_number_2_per_day:
    "Zgodnie z Państwa informacjami zalecamy karmienie 2 razy w ciagu dnia",
  text_calc_modal_recommendation_feeding_number_3_5_per_day:
    "Zgodnie z Państwa informacjami zalecamy karmienie od 3 do 5 razy w ciagu dnia",
  text_calc_modal_recommendation_close_button:
    "Zamknięcie zaleceń dotyczących karmienia",
  text_myfish_page_title: "Moje Ryby",
  text_myfish_pond_col_fish: "Ryba",
  text_myfish_pond_col_quantity: "Ilość",
  text_myfish_pond_col_length: "Rozmiar",
  text_myfish_pond_col_body_shape: "Kształt",
  text_myfish_pond_no_fishes:
    "Brak dostępnych ryb. Najpierw wpisz swoje koi w zakładce Ryby.",
  text_myfish_pond_modal_create_headline: "Stwórz twoje oczko wodne",
  text_myfish_pond_modal_pond_name_headline: "Opis",
  text_myfish_pond_modal_pond_name_placeholder: "Oczko wodne",
  text_myfish_pond_modal_create_button_save: "Zapisz twoje oczko wodne",
  text_myfish_pond_modal_edit_headline: "Zmień oczko wodne",
  text_myfish_fish_modal_create_headline: "Dodaj ryby",
  text_myfish_fish_modal_edit_headline: "Zmień ryby",
  text_myfish_fish_modal_pond_headline: "Opis",
  text_myfish_fish_modal_pond_placeholder: "Koi",
  text_myfish_fish_modal_pond_select_headline: "Oczko wodne",
  text_myfish_fish_modal_quantity_headline: "Ilość",
  text_myfish_fish_modal_length_headline: "Rozmiar (cm)",
  text_myfish_fish_modal_body_shape_headline: "Kształt",
  text_myfish_fish_modal_body_shape_type1: "Szczupłe",
  text_myfish_fish_modal_body_shape_type1_description:
    "Głównie źle odżywione i płci męskiej ryby",
  text_myfish_fish_modal_body_shape_type2: "Normalne",
  text_myfish_fish_modal_body_shape_type2_description:
    "Dobrze odżywione, niezbyt otyłe ryby",
  text_myfish_fish_modal_body_shape_type3: "Korpulentne",
  text_myfish_fish_modal_body_shape_type3_description:
    "Ryby przekarmione ze skłonnościami do tycia",
  text_myfish_fish_modal_create_button_save: "Zapisz wybór",
  text_myfish_toast_entry_saved: "Zapisano",
  text_myfish_toast_entry_deleted: "Wpis usunięto",
  text_more_page_title: "Więcej",
  text_more_help_title: "Pomoc",
  text_more_help_modal_calc_instruction_headline:
    "Jak działa kalkulator pokarmu?",
  text_more_help_modal_calc_instruction_description:
    'Aby skorzystać z kalkulatora pokarmu- wykonaj 3 kroki: <ol><li>W zakładce "RYBY" za pomocą przycisku + dodaj oczko wodne i ryby.</li><li>W zakładce "KALKULATOR" wybierz karmę Koisklep, stan/ilość ryb i aktualną temperaturę wody.</li><li>Użyj przycisku „Oblicz karmienie” w zakładce „Kalkulator”, aby uzyskać dostęp do indywidualnie obliczonych ilości karmy dla utworzonych stawów.</li></ol>',
  text_more_help_modal_save_data_headline: "Gdzie są przechowywane dane ryb?",
  text_more_help_modal_save_data_description:
    "Wprowadzone dane zostaną zapisane tylko na Twoim urządzeniu. Pamiętaj, że jeśli usuniesz aplikację Koisklep, wszystkie wprowadzone dane dotyczące stawów i ryb zostaną utracone.",
  text_more_help_modal_contact_headline:
    "Masz pytania, sugestie lub pomysły dotyczące ulepszeń?",
  text_more_help_modal_contact_description:
    'Jeśli masz jakieś pytania, sugestie lub uwagi - napisz do nas na adres <a href="mailto:info@koisklep.pl">info@koisklep.pl</a>.',
  text_more_imprint_title: "Dane Firmy",
  text_more_data_privacy_title: "Polityka prywatności",
  text_more_open_source_licenses_title: "Licencje open source",
  text_more_delete_storage_title: "Wyczyść pamięć aplikacji",
  text_more_delete_storage_popup_headline: "Wyczyść pamięć aplikacji",
  text_more_delete_storage_popup_description:
    "Wszystkie koi i oczka wodne zostaną usunięte.<br/><br/>Jesteś pewny/pewna?",
  text_more_delete_storage_popup_button_abort: "Anulować",
  text_more_delete_storage_popup_button_confirm: "Potwierdzić",
  text_more_delete_storage_toast: "Pamięć aplikacji została wyczyszczona",
  text_more_imprint_company_name: "KOI CENTRUM Łukasz Gładki",
  text_more_imprint_company_meta:
    "<p>Dane kontaktowe<br/>Adres: ul. Łubniańska 19b , 46-024 Brynica<br /></p><p>Telefon: +48 502529703<br />E-mail: info@koisklep.pl</p><br /><p>NIP: 991 023 54 89</p>",
  //   text_more_imprint_resolution_headline: "",
  //   text_more_imprint_resolution_description: "",
  //   text_more_imprint_responsible_headline: "",
  //   text_more_imprint_responsible_description: "",
  text_myfish_pond_modal_edit_delete_alert_headline: "Wyczyść staw",
  text_myfish_pond_modal_edit_delete_alert_description:
    "Staw i wszystkie ryby są w nich nieodstawione. <br/> <br/> Jesteś pewny?",
  text_myfish_pond_modal_edit_delete_alert_button_abort: "Anulować",
  text_myfish_pond_modal_edit_delete_alert_button_confirm: "Gasić",
  text_myfish_modal_validation_toast: "Proszę wypełnić wszystkie pola!",
  text_myfish_button_new_pond: "Nowy staw",
  text_myfish_button_new_fish: "Nowa ryba",
  text_myfish_fish_modal_edit_delete_alert_headline: "Wyczyść ryba",
  text_myfish_fish_modal_edit_delete_alert_description:
    "Wpis ryb jest nieodwołalnie usunięty. <br/> <br/> Jesteś pewny?",
  text_myfish_fish_modal_edit_delete_alert_button_abort: "Anulować",
  text_myfish_fish_modal_edit_delete_alert_button_confirm: "Gasić",
  text_myfish_fish_modal_select_button_abort: "Anulować",
  text_myfish_fish_modal_select_button_confirm: "Potwierdzać",
  //   text_calc_modal_recommendation_gold360_feeding_number_never:
  //     "Bei diesen Bedingungen ist eine Fütterung nicht zu empfehlen",
  //   text_calc_modal_recommendation_gold360_feeding_number_7_14_days:
  //     "Ihren Angaben nach empfehlen wir eine Fütterung alle 7 bis 14 Tage.",
  //   text_calc_modal_recommendation_gold360_feeding_number_2_4_days:
  //     "Ihren Angaben nach empfehlen wir eine Fütterung alle 2 bis 4 Tage.",
  //   text_calc_modal_recommendation_gold360_feeding_number_1_2_per_day:
  //     "Ihren Angaben nach empfehlen wir die Fütterung 1 bis 2 Mal am Tag.",
  //   text_calc_modal_recommendation_gold360_feeding_number_2_3_per_day:
  //     "Ihren Angaben nach empfehlen wir die Fütterung 2 bis 3 Mal am Tag.",
  //   text_calc_modal_recommendation_gold360_feeding_number_4_5_per_day:
  //     "Ihren Angaben nach empfehlen wir die Fütterung 4 bis 5 Mal am Tag.",
  //   text_calc_modal_recommendation_gold360_feeding_number_3_7_days:
  //     "Ihren Angaben nach empfehlen wir eine Fütterung alle 3 bis 7 Tage.",
  //   text_calc_modal_recommendation_gold360_feeding_number_1_per_day:
  //     "Ihren Angaben nach empfehlen wir die Fütterung 1 Mal am Tag.",
  //   text_calc_modal_recommendation_gold360_feeding_number_2_per_day:
  //     "Ihren Angaben nach empfehlen wir die Fütterung 2 Mal am Tag.",
  //   text_calc_modal_recommendation_gold360_feeding_number_3_5_per_day:
  //     "Ihren Angaben nach empfehlen wir die Fütterung 3 bis 5 Mal am Tag.",
  text_calc_toast_no_fish:
    "Brak dostępnych ryb. Najpierw wpisz swoją rybę w pasującym jeźdźca.",
  text_calc_modal_recommendation_feeding_individual: "indywidualnie",
  text_calc_modal_recommendation_cloze_koi360_1: "dla",
  text_calc_modal_recommendation_cloze_koi360_2:
    ". Aby uzyskać optymalne rozprowadzenie pokarmu, podawaj",
  text_calc_modal_recommendation_cloze_koi360_3:
    " g obliczonej ilości pokarmu jako karmę opadającą (KOISKLEP GOLD opadający) aby nieśmiałe koi dostały swoją porcję pokarmu.",
  //   text_calc_modal_recommendation_cloze_gold360_1:
  //     " Füttern Sie Ihre Goldfische mit kleinen Mengen Gold360, die innerhalb von 3 bis 5 Minuten vollständig aufgefressen werden sollten. Bei vollständiger Appetitlosigkeit entfernen Sie bitte große Futtermengen wieder aus dem Teich.",
  text_calc_modal_recommendation_cloze_no_fish_1: "To może być ",
  text_calc_modal_recommendation_cloze_no_fish_2:
    "  Niestety, nie otrzyma zalecenia karmienia, ponieważ nie było tam żadnych ryb. Najpierw umieść ryby.",
};
