import TankEntry from "./TankEntry";
import { lang } from "../lang/pl_koisklep";

class Calculation {
  feedType: string;
  growthGoal: string;
  pondTemperature: number;
  tankEntries: Array<TankEntry>;

  constructor(
    _feedType: string,
    _growthGoal: string,
    _pondTemperature: number,
    _tankEntries: Array<TankEntry>
  ) {
    this.feedType = _feedType;
    this.growthGoal = _growthGoal;
    this.pondTemperature = _pondTemperature;
    this.tankEntries = _tankEntries;
  }

  isFishArrayEmpty() {
    let fishCounter = 0;

    if (this.tankEntries !== null) {
      if (this.tankEntries.length > 0) {
        this.tankEntries.forEach((tankItem) => {
          fishCounter = fishCounter + tankItem.fishEntries.length;
        });
      }
    }

    if (fishCounter <= 0) {
      return true;
    } else {
      return false;
    }
  }

  calculateFeedingNumber() {
    // calculate feeding numbers per feeding in days
    let feedingNumber: string = "0";

    if (this.feedType !== "gold360") {
      if (this.pondTemperature <= 5) {
        feedingNumber = "never";
      } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
        feedingNumber = "7-14 days";
      } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
        feedingNumber = "2-4 days";
      } else if (this.pondTemperature > 11 && this.pondTemperature <= 15) {
        feedingNumber = "1-2 per day";
      } else if (this.pondTemperature > 15 && this.pondTemperature <= 19) {
        feedingNumber = "2-3 per day";
      } else if (this.pondTemperature > 19 && this.pondTemperature <= 29) {
        feedingNumber = "4-5 per day";
      } else if (this.pondTemperature <= 30) {
        feedingNumber = "never";
      }
    }

    if (this.feedType === "gold360") {
      if (this.pondTemperature <= 7) {
        feedingNumber = "never";
      } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
        feedingNumber = "3-7 days";
      } else if (this.pondTemperature > 11 && this.pondTemperature <= 15) {
        feedingNumber = "1 per day";
      } else if (this.pondTemperature > 15 && this.pondTemperature <= 19) {
        feedingNumber = "2 per day";
      } else if (this.pondTemperature > 19 && this.pondTemperature <= 30) {
        feedingNumber = "3-5 per day";
      } else if (this.pondTemperature < 30) {
        feedingNumber = "never";
      }
    }

    return feedingNumber;
  }

  calculateTemperatureValue() {
    let temperatureValue: number = 0;

    if (this.feedType === "koi360") {
      if (this.growthGoal === "regular-growth") {
        // mixed pond big koi
        if (this.pondTemperature <= 5) {
          temperatureValue = 0;
        } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
          temperatureValue = 0.05;
        } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
          temperatureValue = 0.1;
        } else if (this.pondTemperature > 11 && this.pondTemperature <= 13) {
          temperatureValue = 0.15;
        } else if (this.pondTemperature > 13 && this.pondTemperature <= 15) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature > 12 && this.pondTemperature <= 14) {
          temperatureValue = 0.243;
        } else if (this.pondTemperature > 15 && this.pondTemperature <= 18) {
          temperatureValue = 0.3;
        } else if (this.pondTemperature > 18 && this.pondTemperature <= 20) {
          temperatureValue = 0.35;
        } else if (this.pondTemperature === 21) {
          temperatureValue = 0.5;
        } else if (this.pondTemperature > 21 && this.pondTemperature <= 26) {
          temperatureValue = 0.55;
        } else if (this.pondTemperature > 26 && this.pondTemperature <= 28) {
          temperatureValue = 0.4;
        } else if (this.pondTemperature === 29) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature >= 30) {
          temperatureValue = 0;
        }
      } else if (this.growthGoal === "slow-growth") {
        // mixed pond small koi
        if (this.pondTemperature <= 5) {
          temperatureValue = 0;
        } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
          temperatureValue = 0.05;
        } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
          temperatureValue = 0.1;
        } else if (this.pondTemperature > 11 && this.pondTemperature <= 13) {
          temperatureValue = 0.15;
        } else if (this.pondTemperature > 13 && this.pondTemperature <= 15) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature > 15 && this.pondTemperature <= 17) {
          temperatureValue = 0.4;
        } else if (this.pondTemperature > 17 && this.pondTemperature <= 19) {
          temperatureValue = 0.6;
        } else if (this.pondTemperature > 19 && this.pondTemperature <= 21) {
          temperatureValue = 0.9;
        } else if (this.pondTemperature > 21 && this.pondTemperature <= 26) {
          temperatureValue = 1;
        } else if (this.pondTemperature === 27) {
          temperatureValue = 0.9;
        } else if (this.pondTemperature === 28) {
          temperatureValue = 0.5;
        } else if (this.pondTemperature === 29) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature >= 30) {
          temperatureValue = 0;
        }
      } else if (this.growthGoal === "fast-growth") {
        // young koi breeding
        if (this.pondTemperature <= 5) {
          temperatureValue = 0;
        } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
          temperatureValue = 0.05;
        } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
          temperatureValue = 0.1;
        } else if (this.pondTemperature > 11 && this.pondTemperature <= 13) {
          temperatureValue = 0.15;
        } else if (this.pondTemperature > 13 && this.pondTemperature <= 15) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature > 15 && this.pondTemperature <= 17) {
          temperatureValue = 0.4;
        } else if (this.pondTemperature === 18) {
          temperatureValue = 0.8;
        } else if (this.pondTemperature === 19) {
          temperatureValue = 0.9;
        } else if (this.pondTemperature > 19 && this.pondTemperature <= 21) {
          temperatureValue = 1;
        } else if (this.pondTemperature > 21 && this.pondTemperature <= 26) {
          temperatureValue = 1.5;
        } else if (this.pondTemperature === 27) {
          temperatureValue = 1.2;
        } else if (this.pondTemperature === 28) {
          temperatureValue = 0.8;
        } else if (this.pondTemperature === 29) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature >= 30) {
          temperatureValue = 0;
        }
      }
    }

    if (this.feedType === "koibasic") {
      if (this.growthGoal === "regular-growth") {
        // mixed pond big koi
        if (this.pondTemperature <= 5) {
          temperatureValue = 0;
        } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
          temperatureValue = 0.05;
        } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
          temperatureValue = 0.1;
        } else if (this.pondTemperature > 11 && this.pondTemperature <= 13) {
          temperatureValue = 0.15;
        } else if (this.pondTemperature > 13 && this.pondTemperature <= 15) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature > 15 && this.pondTemperature <= 17) {
          temperatureValue = 0.3;
        } else if (this.pondTemperature === 18) {
          temperatureValue = 0.4;
        } else if (this.pondTemperature > 18 && this.pondTemperature <= 20) {
          temperatureValue = 0.5;
        } else if (this.pondTemperature === 21) {
          temperatureValue = 0.6;
        } else if (this.pondTemperature > 21 && this.pondTemperature <= 26) {
          temperatureValue = 0.7;
        } else if (this.pondTemperature > 26 && this.pondTemperature <= 28) {
          temperatureValue = 0.5;
        } else if (this.pondTemperature === 29) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature >= 30) {
          temperatureValue = 0;
        }
      } else if (this.growthGoal === "slow-growth") {
        // mixed pond small koi
        if (this.pondTemperature <= 5) {
          temperatureValue = 0;
        } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
          temperatureValue = 0.05;
        } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
          temperatureValue = 0.1;
        } else if (this.pondTemperature > 11 && this.pondTemperature <= 13) {
          temperatureValue = 0.15;
        } else if (this.pondTemperature > 13 && this.pondTemperature <= 15) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature > 15 && this.pondTemperature <= 17) {
          temperatureValue = 0.4;
        } else if (this.pondTemperature === 18) {
          temperatureValue = 0.6;
        } else if (this.pondTemperature === 19) {
          temperatureValue = 0.8;
        } else if (this.pondTemperature === 20) {
          temperatureValue = 1;
        } else if (this.pondTemperature > 20 && this.pondTemperature <= 26) {
          temperatureValue = 1.1;
        } else if (this.pondTemperature === 27) {
          temperatureValue = 0.9;
        } else if (this.pondTemperature === 28) {
          temperatureValue = 0.5;
        } else if (this.pondTemperature === 29) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature >= 30) {
          temperatureValue = 0;
        }
      } else if (this.growthGoal === "fast-growth") {
        // young koi breeding
        if (this.pondTemperature <= 5) {
          temperatureValue = 0;
        } else if (this.pondTemperature > 5 && this.pondTemperature <= 7) {
          temperatureValue = 0.05;
        } else if (this.pondTemperature > 7 && this.pondTemperature <= 11) {
          temperatureValue = 0.1;
        } else if (this.pondTemperature > 11 && this.pondTemperature <= 13) {
          temperatureValue = 0.15;
        } else if (this.pondTemperature > 13 && this.pondTemperature <= 15) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature > 15 && this.pondTemperature <= 17) {
          temperatureValue = 0.4;
        } else if (this.pondTemperature === 18) {
          temperatureValue = 0.8;
        } else if (this.pondTemperature === 19) {
          temperatureValue = 1;
        } else if (this.pondTemperature > 20 && this.pondTemperature <= 21) {
          temperatureValue = 1.2;
        } else if (this.pondTemperature === 22) {
          temperatureValue = 1.4;
        } else if (this.pondTemperature > 22 && this.pondTemperature <= 25) {
          temperatureValue = 1.6;
        } else if (this.pondTemperature === 26) {
          temperatureValue = 1.5;
        } else if (this.pondTemperature === 27) {
          temperatureValue = 1.2;
        } else if (this.pondTemperature === 28) {
          temperatureValue = 0.8;
        } else if (this.pondTemperature === 29) {
          temperatureValue = 0.2;
        } else if (this.pondTemperature >= 30) {
          temperatureValue = 0;
        }
      }
    }

    temperatureValue = temperatureValue / 100;
    return temperatureValue;
  }

  translateFeedType() {
    if (this.feedType === "koibasic") return lang.text_calc_feed_type_name1;
    if (this.feedType === "koi360") return lang.text_calc_feed_type_name2;
    if (this.feedType === "gold360") return "Nutramare Gold360";
  }

  calculateFeeding() {
    let feedingValues = [];
    let tankWeight = 0;

    this.tankEntries?.map((tankItem) => {
      tankWeight = 0;

      tankItem.fishEntries?.map((fishItem) => {
        tankWeight = tankWeight + fishItem.weight;
        return tankWeight;
      });

      tankWeight = tankWeight * this.calculateTemperatureValue();

      feedingValues[tankItem.name] = tankWeight;
    });
    return feedingValues;
  }

  translateFeedingNumber() {
    let feedingNumber = this.calculateFeedingNumber();
    let feedingText: string = "";

    if (this.feedType !== "gold360") {
      if (feedingNumber === "never") {
        feedingText = lang.text_calc_modal_recommendation_feeding_number_never;
      } else if (feedingNumber === "7-14 days") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_7_14_days;
      } else if (feedingNumber === "2-4 days") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_2_4_days;
      } else if (feedingNumber === "1-2 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_1_2_per_day;
      } else if (feedingNumber === "2-3 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_2_3_per_day;
      } else if (feedingNumber === "4-5 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_4_5_per_day;
      } else if (feedingNumber === "3-7 days") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_3_7_days;
      } else if (feedingNumber === "1 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_1_per_day;
      } else if (feedingNumber === "2 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_2_per_day;
      } else if (feedingNumber === "3-5 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_feeding_number_3_5_per_day;
      }
    }

    if (this.feedType === "gold360") {
      if (feedingNumber === "never") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_never;
      } else if (feedingNumber === "7-14 days") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_7_14_days;
      } else if (feedingNumber === "2-4 days") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_2_4_days;
      } else if (feedingNumber === "1-2 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_1_2_per_day;
      } else if (feedingNumber === "2-3 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_2_3_per_day;
      } else if (feedingNumber === "4-5 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_4_5_per_day;
      } else if (feedingNumber === "3-7 days") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_3_7_days;
      } else if (feedingNumber === "1 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_1_per_day;
      } else if (feedingNumber === "2 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_2_per_day;
      } else if (feedingNumber === "3-5 per day") {
        feedingText =
          lang.text_calc_modal_recommendation_gold360_feeding_number_3_5_per_day;
      }
    }

    return feedingText;
  }
}

export default Calculation;
