import React, { useState, useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonFab,
  IonFabList,
  IonFabButton,
  IonButton,
  IonToast,
  IonText,
  IonList,
} from "@ionic/react";
import { plainToClass } from "class-transformer";

import { add, fish } from "ionicons/icons";
import "./MyFish.css";
import TankEntry from "../services/TankEntry";
import {
  saveTankArrayToStorage,
  findAndGetTank,
  deleteTank,
} from "../services/storage-helper";

import FishEditModal from "../modals/FishEditModal";
import TankEditModal from "../modals/TankEditModal";

const MyFish: React.FC<any> = ({
  store,
  fishModalData,
  setFishModalData,
  storedTankEntries,
  setStoredTankEntries,
  lang,
}) => {
  const [showFishModal, setShowFishModal] = useState(false);
  const [showTankModal, setShowTankModal] = useState(false);
  const [showFishConfirmationToast, setShowFishConfirmationToast] =
    useState(false);
  const [storedFishOperation, setStoredFishOperation] = useState("");

  let localStoredTankEntries = new Array<TankEntry>();

  useEffect(() => {
    store.get("tanks").then((tankEntries) => {
      let localStoredTankEntries = JSON.parse(tankEntries);
      localStoredTankEntries = plainToClass(TankEntry, localStoredTankEntries);
      setStoredTankEntries(localStoredTankEntries);
    });
  }, []);

  localStoredTankEntries = storedTankEntries;

  useEffect(() => {
    if (fishModalData.saveType === "create") {
      findAndGetTank(
        fishModalData.tankIdentification,
        localStoredTankEntries
      ).createFish(
        fishModalData.fishQuantity,
        fishModalData.fishLength,
        fishModalData.fishBodyShape,
        fishModalData.fishName
      );
      saveTankArrayToStorage(
        store,
        localStoredTankEntries,
        setStoredTankEntries
      );
      setStoredFishOperation("save");
      setShowFishConfirmationToast(true);
      resetFishModalData();
    }

    if (fishModalData.saveType === "update") {
      findAndGetTank(
        fishModalData.tankIdentification,
        localStoredTankEntries
      ).updateFish(
        fishModalData.fishIdentification,
        fishModalData.fishQuantity,
        fishModalData.fishLength,
        fishModalData.fishBodyShape,
        fishModalData.fishName
      );
      saveTankArrayToStorage(
        store,
        localStoredTankEntries,
        setStoredTankEntries
      );
      setStoredFishOperation("save");
      setShowFishConfirmationToast(true);
      resetFishModalData();
    }

    if (fishModalData.saveType === "delete") {
      findAndGetTank(
        fishModalData.tankIdentification,
        localStoredTankEntries
      ).deleteFish(fishModalData.fishIdentification);
      saveTankArrayToStorage(
        store,
        localStoredTankEntries,
        setStoredTankEntries
      );
      setStoredFishOperation("delete");
      setShowFishConfirmationToast(true);
      resetFishModalData();
    }

    if (fishModalData.saveType === "create-tank") {
      localStoredTankEntries.push(new TankEntry(fishModalData.tankName));
      saveTankArrayToStorage(
        store,
        localStoredTankEntries,
        setStoredTankEntries
      );
      setStoredFishOperation("save");
      setShowFishConfirmationToast(true);
      resetFishModalData();
    }

    if (fishModalData.saveType === "update-tank") {
      findAndGetTank(
        fishModalData.tankIdentification,
        localStoredTankEntries
      ).renameTank(fishModalData.tankName);
      saveTankArrayToStorage(
        store,
        localStoredTankEntries,
        setStoredTankEntries
      );
      setStoredFishOperation("save");
      setShowFishConfirmationToast(true);
      resetFishModalData();
    }

    if (fishModalData.saveType === "delete-tank") {
      deleteTank(
        store,
        fishModalData.tankIdentification,
        localStoredTankEntries,
        setStoredTankEntries
      );
      setStoredFishOperation("delete");
      setShowFishConfirmationToast(true);
      resetFishModalData();
    }
  }, [fishModalData]);

  function resetFishModalData() {
    setFishModalData({
      fishQuantity: 0,
      fishLength: 0,
      fishBodyShape: "",
      fishIdentification: "",
      saveType: "none",
      tankIdentification: "",
      tankName: "",
    });
  }

  function translateBodyShape(bodyShape) {
    let bodyShapeTranslation: string = "";

    if (bodyShape === "slim") {
      bodyShapeTranslation = lang.text_myfish_fish_modal_body_shape_type1;
    } else if (bodyShape === "regular") {
      bodyShapeTranslation = lang.text_myfish_fish_modal_body_shape_type2;
    } else if (bodyShape === "fat") {
      bodyShapeTranslation = lang.text_myfish_fish_modal_body_shape_type3;
    }
    return bodyShapeTranslation;
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonText className="page-title">
                {lang.text_myfish_page_title}
              </IonText>
            </IonCol>
          </IonRow>
          {localStoredTankEntries !== null ? (
            localStoredTankEntries.length > 0 ? (
              localStoredTankEntries?.map((tankItem, tankIndex) => {
                return (
                  <IonCard mode="ios" key={tankIndex}>
                    <IonCardHeader>
                      <IonRow className="ion-display-flex ion-align-items-baseline">
                        <IonCol size="10">
                          <IonText
                            className="tank-headline"
                            onClick={() => {
                              setFishModalData({
                                saveType: "to-update-tank",
                                tankIdentification: tankItem.entryId,
                                tankName: tankItem.name,
                              });
                              setShowTankModal(true);
                            }}>
                            {tankItem.name}
                          </IonText>
                        </IonCol>
                        <IonCol
                          size="2"
                          className="tank-add-fish"
                          onClick={() => {
                            setFishModalData({
                              saveType: "to-create",
                              tankIdentification: tankItem.entryId,
                              tankName: tankItem.name,
                            });
                            setShowFishModal(true);
                          }}>
                          <IonIcon icon={add} />
                          <IonIcon icon={fish} />
                        </IonCol>
                        <IonCol size="1" className="ion-text-right"></IonCol>
                      </IonRow>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonList>
                        <IonRow className="ion-text-center fish-entry-header">
                          <IonCol>{lang.text_myfish_pond_col_fish}</IonCol>
                          <IonCol>{lang.text_myfish_pond_col_quantity}</IonCol>
                          <IonCol>{lang.text_myfish_pond_col_length}</IonCol>
                          <IonCol>
                            {lang.text_myfish_pond_col_body_shape}
                          </IonCol>
                        </IonRow>
                        {tankItem.fishEntries.length !== 0 &&
                        tankItem.fishEntries.length !== undefined ? (
                          tankItem.fishEntries.map((fish, fishIndex) => {
                            return (
                              <IonRow
                                className="ion-text-center fish-entry-row"
                                key={fishIndex}
                                onClick={() => {
                                  setFishModalData({
                                    fishQuantity: fish.quantity,
                                    fishLength: fish.length,
                                    fishBodyShape: fish.bodyShape,
                                    fishName: fish.name,
                                    fishIdentification: fish.entryId,
                                    saveType: "to-update",
                                    tankIdentification: tankItem.entryId,
                                    tankName: tankItem.name,
                                  });
                                  setShowFishModal(true);
                                }}>
                                <IonCol>{fish.name}</IonCol>
                                <IonCol>{fish.quantity}</IonCol>
                                <IonCol>{fish.length} cm</IonCol>
                                <IonCol>
                                  {translateBodyShape(fish.bodyShape)}
                                </IonCol>
                              </IonRow>
                            );
                          })
                        ) : (
                          <IonRow className="ion-text-center fish-entry-row">
                            <IonCol>
                              <IonText color="danger">
                                {lang.text_myfish_pond_no_fishes}
                              </IonText>
                            </IonCol>
                          </IonRow>
                        )}
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                );
              })
            ) : (
              <IonRow className="ion-text-center fish-entry-row">
                <IonCol>
                  <IonText color="danger">
                    {lang.text_myfish_pond_no_fishes}
                  </IonText>
                </IonCol>
              </IonRow>
            )
          ) : (
            <IonRow className="ion-text-center fish-entry-row">
              <IonCol>
                <IonText color="danger">
                  {lang.text_myfish_pond_no_fishes}
                </IonText>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
          <IonFabList side="start">
            <FishEditModal
              showFishModal={showFishModal}
              setShowFishModal={setShowFishModal}
              fishModalData={fishModalData}
              setFishModalData={setFishModalData}
              storedTankEntries={storedTankEntries}
              lang={lang}
            />
            <TankEditModal
              showTankModal={showTankModal}
              setShowTankModal={setShowTankModal}
              fishModalData={fishModalData}
              setFishModalData={setFishModalData}
              lang={lang}
            />
            <IonButton
              onClick={() => {
                setShowFishModal(true);
                setFishModalData({ saveType: "to-create" });
              }}>
              {lang.text_myfish_button_new_fish}
            </IonButton>
            <IonButton
              onClick={() => {
                setShowTankModal(true);
                setFishModalData({ saveType: "to-create-tank" });
              }}>
              {lang.text_myfish_button_new_pond}
            </IonButton>
          </IonFabList>
        </IonFab>
        <IonToast
          isOpen={showFishConfirmationToast}
          message={
            storedFishOperation === "save"
              ? lang.text_myfish_toast_entry_saved
              : lang.text_myfish_toast_entry_deleted
          }
          duration={1500}
          color="success"
          mode="ios"
          cssClass="ion-text-center"
          onDidDismiss={() => setShowFishConfirmationToast(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default MyFish;
