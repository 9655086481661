import FishEntry from "./FishEntry";

class TankEntry {
  entryId: number;
  name: string;
  fishEntries: Array<FishEntry>;

  constructor(_name: string) {
    this.entryId = Date.now();
    this.name = _name;
    this.fishEntries = [];
  }

  createFish(quantity, length, bodyShape, name) {
    let newFish = new FishEntry(quantity, length, bodyShape, name);
    this.fishEntries.push(newFish);
  }

  findAndGetFishIndex(fishId) {
    let entryIndex = this.fishEntries.findIndex((x) => x.entryId === fishId);
    return entryIndex;
  }

  findAndGetFish(fishId) {
    let entryIndex = this.fishEntries.findIndex((x) => x.entryId === fishId);
    let foundFish = this.fishEntries[entryIndex];
    return foundFish;
  }

  updateFish(fishId, quantity, length, bodyShape, name) {
    let foundFish = this.findAndGetFish(fishId);
    foundFish.quantity = quantity;
    foundFish.length = length;
    foundFish.bodyShape = bodyShape;
    foundFish.name = name;

    let tempFish = new FishEntry(quantity, length, bodyShape, name);
    foundFish.weight = tempFish.weight;
  }

  deleteFish(fishId) {
    let foundFishIndex = this.findAndGetFishIndex(fishId);
    this.fishEntries.splice(foundFishIndex, 1);
  }

  renameTank(newName) {
    this.name = newName;
  }
}

export default TankEntry;
