import React, { useState } from "react";

import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonText,
  IonAlert,
  IonToast,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { setInitialValues } from "../services/storage-helper";
import "./More.css";
import { trashOutline } from "ionicons/icons";

import ImprintModal from "../modals/ImprintModal";
import PrivacyModal from "../modals/PrivacyModal";
import LicensesModal from "../modals/LicensesModal";
import HelpModal from "../modals/HelpModal";

const More: React.FC<any> = ({ store, lang }) => {
  const [showClearAlert, setShowClearAlert] = useState(false);
  const [showStorageDeletedToast, setShowStorageDeletedToast] = useState(false);
  const [showImprintModal, setShowImprintModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showLicensesModal, setshowLicensesModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonText className="page-title">
                {lang.text_more_page_title}
              </IonText>
            </IonCol>
          </IonRow>
          <IonCard mode="ios">
            <IonCardContent>
              <IonRow>
                <IonCol>
                  <IonList>
                    <IonMenuToggle auto-hide="false">
                      <IonItem
                        button
                        onClick={() => {
                          setShowHelpModal(true);
                        }}>
                        {lang.text_more_help_title}
                      </IonItem>
                      <HelpModal
                        showHelpModal={showHelpModal}
                        setShowHelpModal={setShowHelpModal}
                        lang={lang}
                      />
                      <IonItem
                        button
                        onClick={() => {
                          setShowImprintModal(true);
                        }}>
                        {lang.text_more_imprint_title}
                      </IonItem>
                      <ImprintModal
                        showImprintModal={showImprintModal}
                        setShowImprintModal={setShowImprintModal}
                        lang={lang}
                      />
                      <IonItem
                        button
                        onClick={() => {
                          setShowPrivacyModal(true);
                        }}>
                        {lang.text_more_data_privacy_title}
                      </IonItem>
                      <PrivacyModal
                        showPrivacyModal={showPrivacyModal}
                        setShowPrivacyModal={setShowPrivacyModal}
                        lang={lang}
                      />
                      <IonItem
                        button
                        onClick={() => {
                          setshowLicensesModal(true);
                        }}>
                        {lang.text_more_open_source_licenses_title}
                      </IonItem>
                      <LicensesModal
                        showLicensesModal={showLicensesModal}
                        setShowLicensesModal={setshowLicensesModal}
                        lang={lang}
                      />
                      <IonItem
                        button
                        onClick={() => {
                          setShowClearAlert(true);
                        }}>
                        <IonText>{lang.text_more_delete_storage_title}</IonText>
                        <IonIcon
                          icon={trashOutline}
                          size="small"
                          slot="end"
                          color="danger"
                        />
                      </IonItem>
                      <IonAlert
                        isOpen={showClearAlert}
                        onDidDismiss={() => setShowClearAlert(false)}
                        header={lang.text_more_delete_storage_popup_headline}
                        message={
                          lang.text_more_delete_storage_popup_description
                        }
                        buttons={[
                          {
                            text: lang.text_more_delete_storage_popup_button_abort,
                            role: "cancel",
                          },
                          {
                            text: lang.text_more_delete_storage_popup_button_confirm,
                            handler: () => {
                              store.clear();
                              setInitialValues(store);
                              setShowStorageDeletedToast(true);
                            },
                          },
                        ]}
                      />
                      <IonToast
                        isOpen={showStorageDeletedToast}
                        message={lang.text_more_delete_storage_toast}
                        duration={2500}
                        mode="ios"
                        cssClass="ion-text-center"
                        color="success"
                        onDidDismiss={() => setShowStorageDeletedToast(false)}
                      />
                    </IonMenuToggle>
                  </IonList>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-bottom">
                <IonCol></IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
          <IonRow>
            <IonCol>
              <IonText
                className="ion-margin-top ion-margin-horizontal"
                color="medium">
                Wersja: 2.0.3
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default More;
