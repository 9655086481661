class FishEntry {
  entryId: number;
  name: string;
  quantity: number;
  length: number;
  bodyShape: string;
  weight: number;

  constructor(
    _quantity: number,
    _length: number,
    _bodyShape: string,
    _name: string
  ) {
    this.entryId = Date.now();
    this.name = _name;
    this.quantity = _quantity;
    this.length = _length;
    this.bodyShape = _bodyShape;
    this.weight = this.calculateWeight();
  }

  calculateWeight() {
    let bodyShapeValue = this.translateBodyShapeValue();
    let weight = (bodyShapeValue * this.length ** 3) / 100;
    weight = weight * this.quantity;
    return weight;
  }

  calcAndSetWeight() {
    this.weight = this.calculateWeight();
  }

  translateBodyShapeValue() {
    let bodyShapeValue: number = 0;

    if (this.bodyShape === "slim") {
      bodyShapeValue = 1.3;
    } else if (this.bodyShape === "regular") {
      bodyShapeValue = 1.5;
    } else if (this.bodyShape === "fat") {
      bodyShapeValue = 2.0;
    }
    return bodyShapeValue;
  }
}

export default FishEntry;
