import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRange,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToast,
} from "@ionic/react";
import {
  addCircle,
  addCircleOutline,
  heart,
  snow,
  sunny,
} from "ionicons/icons";
import "./Calculator.css";
import {
  saveFeedTypeToStorage,
  saveGrowthGoalToStorage,
  saveWaterTemperatureToStorage,
  loadFeedTypeFromStorage,
  loadGrowthGoalFromStorage,
  loadWaterTemperatureFromStorage,
} from "../services/storage-helper";
import parse from "html-react-parser";

import CalculationResultModal from "../modals/CalculationResultModal";
import { loadTankArrayFromStorage } from "../services/storage-helper";
import Calculation from "../services/Calculation";
import TankEntry from "../services/TankEntry";

const Calculator: React.FC<any> = ({
  lang,
  store,
  storedTankEntries,
  setStoredTankEntries,
  growthGoal,
  setGrowthGoal,
  feedType,
  setFeedType,
  waterTemperature,
  setWaterTemperature,
}) => {
  let textSlowGrowth = lang.text_calc_fish_stock_type1_description;
  let textRegularGrowth = lang.text_calc_fish_stock_type2_description;
  let textFastGrowth = lang.text_calc_fish_stock_type3_description;

  const [showCalculationResultModal, setShowCalculationResultModal] =
    useState(false);
  const [showCalculatorErrorToast, setShowCalculatorErrorToast] =
    useState(false);
  const [storedCalculation, setStoredCalculation] = useState<Calculation>(
    new Calculation(feedType, growthGoal, waterTemperature, [])
  );
  const [textGrowthDescription, setTextGrowthDescription] =
    useState(textRegularGrowth);

  function switchGrowthDescriptionText() {
    if (growthGoal === "slow-growth") setTextGrowthDescription(textSlowGrowth);
    if (growthGoal === "regular-growth")
      setTextGrowthDescription(textRegularGrowth);
    if (growthGoal === "fast-growth") setTextGrowthDescription(textFastGrowth);
  }

  function showResultModalWhenEnoughFishes(currentCalculation) {
    if (currentCalculation.isFishArrayEmpty() === false) {
      setStoredCalculation(currentCalculation);
      setShowCalculationResultModal(true);
    } else {
      setShowCalculatorErrorToast(true);
    }
  }

  function checkAndSetInitialCalcParams() {
    if (feedType === null) feedType = "koi360";
    if (growthGoal === null) growthGoal = "regular-growth";
    if (waterTemperature === null) {
      waterTemperature = 15;
      storedTankEntries = new TankEntry("Teich 1");
    }
  }

  useEffect(() => {
    loadTankArrayFromStorage(store, setStoredTankEntries);
    loadFeedTypeFromStorage(store, setFeedType);
    loadGrowthGoalFromStorage(store, setGrowthGoal);
    loadWaterTemperatureFromStorage(store, setWaterTemperature);
  }, []);

  useEffect(() => {
    switchGrowthDescriptionText();
    saveGrowthGoalToStorage(store, growthGoal);
  }, [growthGoal]);

  useEffect(() => {
    saveFeedTypeToStorage(store, feedType);
  }, [feedType]);

  useEffect(() => {
    saveWaterTemperatureToStorage(store, waterTemperature);
  }, [waterTemperature]);

  checkAndSetInitialCalcParams();

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonText className="page-title">
                {lang.text_calc_page_title}
              </IonText>
            </IonCol>
          </IonRow>

          <IonCard mode="ios">
            <IonCardContent>
              <IonRow>
                <IonCol className="calc-heading ion-text-center">
                  {lang.text_calc_feed_type_headline}
                </IonCol>
              </IonRow>
              <IonRow className="calc-row">
                <IonCol>
                  <IonSegment
                    mode="md"
                    value={feedType}
                    onIonChange={(e) => setFeedType(e.detail.value as string)}>
                    <IonSegmentButton value="koibasic">
                      <IonLabel className="calc-label">
                        {lang.text_calc_feed_type_name1.split(" ")[0]}
                        <br />
                        {lang.text_calc_feed_type_name1.split(" ")[1]}
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="koi360">
                      <IonLabel className="calc-label">
                        {lang.text_calc_feed_type_name2.split(" ")[0]}
                        <br />
                        {lang.text_calc_feed_type_name2.split(" ")[1]}
                      </IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="calc-heading ion-text-center">
                  {lang.text_calc_fish_stock_headline}
                </IonCol>
              </IonRow>
              <IonRow className="calc-row">
                <IonCol>
                  <IonSegment
                    mode="md"
                    value={growthGoal}
                    onIonChange={(e) => {
                      setGrowthGoal(e.detail.value as string);
                    }}>
                    <IonSegmentButton value="slow-growth">
                      <IonIcon icon={heart} size="small" />
                      <IonLabel className="calc-label">
                        {parse(lang.text_calc_fish_stock_type1)}
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="regular-growth">
                      <IonIcon icon={addCircleOutline} size="small" />
                      <IonLabel className="calc-label">
                        {parse(lang.text_calc_fish_stock_type2)}
                      </IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="fast-growth">
                      <IonIcon icon={addCircle} size="small" />
                      <IonLabel className="calc-label">
                        {parse(lang.text_calc_fish_stock_type3)}
                      </IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                  <div className="calc__growth-description">
                    {textGrowthDescription}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonText className="ion-text-center">
                    <span className="calc-heading">
                      {lang.text_calc_water_temperature_headline}
                    </span>
                  </IonText>
                  <IonText className="ion-text-center" color="primary">
                    <span className="waterTemperature-value">
                      {waterTemperature} °C
                    </span>
                  </IonText>

                  <IonRange
                    min={0}
                    max={30}
                    value={waterTemperature}
                    onIonChange={(e) =>
                      setWaterTemperature(e.detail.value as number)
                    }>
                    <IonIcon icon={snow} slot="start" size="small" />
                    <IonIcon icon={sunny} slot="end" size="small" />
                  </IonRange>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <CalculationResultModal
                    showCalculationResultModal={showCalculationResultModal}
                    setShowCalculationResultModal={
                      setShowCalculationResultModal
                    }
                    storedCalculation={storedCalculation}
                    lang={lang}
                  />
                  <IonButton
                    onClick={() => {
                      let currentCalculation = new Calculation(
                        feedType,
                        growthGoal,
                        waterTemperature,
                        storedTankEntries
                      );
                      setFeedType(feedType);
                      setGrowthGoal(growthGoal);
                      showResultModalWhenEnoughFishes(currentCalculation);
                    }}
                    expand="block"
                    className="calc-button">
                    {lang.text_calc_calculation_button}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonGrid>
        <IonToast
          isOpen={showCalculatorErrorToast}
          message={lang.text_calc_toast_no_fish}
          duration={3500}
          color="danger"
          mode="ios"
          cssClass="ion-text-center"
          onDidDismiss={() => setShowCalculatorErrorToast(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default Calculator;
