import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonText,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import parse from "html-react-parser";

const ImprintModal: React.FC<any> = ({
  showImprintModal,
  setShowImprintModal,
  lang,
}) => {
  return (
    <IonPage>
      <IonModal
        isOpen={showImprintModal}
        mode="ios"
        cssClass="more-modal"
        onDidDismiss={() => setShowImprintModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  setShowImprintModal(false);
                }}>
                <IonIcon icon={arrowBack}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle>{lang.text_more_imprint_title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonCard>
                  <IonCardHeader>
                    <h3>{lang.text_more_imprint_company_name}</h3>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonText>
                      {parse(lang.text_more_imprint_company_meta)}
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default ImprintModal;
