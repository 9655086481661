import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const Shop: React.FC<any> = () => {
  useEffect(() => {
    window.open("https://koisklep.pl/pokarm-dla-karpi-koi/", "_system");
    <Redirect to="/calculator" />;
  }, []);

  return null;
};

export default Shop;
