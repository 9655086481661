import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonIcon,
  IonText,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonLabel,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import "./CalcModal.css";

const CalculationResultModal: React.FC<any> = ({
  showCalculationResultModal,
  setShowCalculationResultModal,
  storedCalculation,
  lang,
}) => {
  const [feedingValues, setFeedingValues] = useState([]);

  useEffect(() => {
    setFeedingValues(storedCalculation.calculateFeeding());
  }, [storedCalculation]);

  return (
    <IonPage>
      <IonModal
        isOpen={showCalculationResultModal}
        mode="ios"
        onDidDismiss={() => setShowCalculationResultModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="dark"
                onClick={() => {
                  setShowCalculationResultModal(false);
                }}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>{lang.text_calc_modal_headline}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            {/* <IonRow>
              <IonCol>
                <IonText
                  color="medium"
                  className="calc-modal__feeding-headline">
                  Fütterung mit {storedCalculation.translateFeedType()}
                </IonText>
              </IonCol>
            </IonRow> */}

            <IonRow>
              <IonCol>
                <IonCard color="medium">
                  <IonCardHeader className="calc-modal__disclaimer-headline">
                    {lang.text_calc_modal_disclaimer_headline}
                  </IonCardHeader>
                  <IonCardContent className="calc-modal__disclaimer-text">
                    {lang.text_calc_modal_disclaimer_description}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {storedCalculation.tankEntries.map((tankItem, tankIndex) => {
              return (
                <IonRow key={tankIndex}>
                  <IonCol>
                    <IonCard>
                      <IonCardContent>
                        <IonRow>
                          <IonCol className="display-flex align-items-center">
                            <IonText className="calc-modal-feeding-card-title">
                              {tankItem.name}
                            </IonText>

                            <IonChip color="secondary">
                              <IonLabel>
                                {storedCalculation.feedType === "gold360" ||
                                tankItem.fishEntries.length <= 0
                                  ? lang.text_calc_modal_recommendation_feeding_individual
                                  : Math.round(feedingValues[tankItem.name]) +
                                    " g"}
                              </IonLabel>
                            </IonChip>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonText className="calc-modal-feeding-card-text">
                              {tankItem.fishEntries.length > 0
                                ? storedCalculation.feedType !== "gold360"
                                  ? storedCalculation.translateFeedingNumber() +
                                    " " +
                                    Math.round(feedingValues[tankItem.name]) +
                                    " g " +
                                    storedCalculation.translateFeedType() +
                                    " " +
                                    lang.text_calc_modal_recommendation_cloze_koi360_1 +
                                    " " +
                                    tankItem.name +
                                    lang.text_calc_modal_recommendation_cloze_koi360_2 +
                                    " " +
                                    Math.round(
                                      feedingValues[tankItem.name] * 0.25
                                    ) +
                                    lang.text_calc_modal_recommendation_cloze_koi360_3
                                  : storedCalculation.translateFeedingNumber() +
                                    lang.text_calc_modal_recommendation_cloze_gold360_1
                                : lang.text_calc_modal_recommendation_cloze_no_fish_1 +
                                  tankItem.name +
                                  lang.text_calc_modal_recommendation_cloze_no_fish_2}
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              );
            })}

            <IonRow>
              <IonCol>
                <IonButton
                  expand="block"
                  onClick={() => {
                    setShowCalculationResultModal(false);
                  }}>
                  {lang.text_calc_modal_recommendation_close_button}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default CalculationResultModal;
