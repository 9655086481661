import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import {
  IonApp,
  IonIcon,
  IonImg,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Storage } from "@ionic/storage";
import { IonReactRouter } from "@ionic/react-router";
import {
  loadFeedTypeFromStorage,
  loadGrowthGoalFromStorage,
  setInitialValues,
  loadTankArrayFromStorage,
  loadWaterTemperatureFromStorage,
} from "./services/storage-helper";
import "reflect-metadata";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables_koisklep.css";

import "./App.css";
import { calculator, basket, keypad, fish } from "ionicons/icons";
import Calculator from "./pages/Calculator";
import More from "./pages/More";
import MyFish from "./pages/MyFish";
import Shop from "./pages/Shop";

import { lang } from "../src/lang/pl_koisklep";

const App = () => {
  const [storedTankEntries, setStoredTankEntries] = useState([]);
  const [store, setStore] = useState(new Storage());
  let isFirstStart = true;
  const [growthGoal, setGrowthGoal] = useState("");
  const [feedType, setFeedType] = useState("");
  const [waterTemperature, setWaterTemperature] = useState(10);
  const [language, setLanguage] = useState("de");

  const [fishModalData, setFishModalData] = useState({
    fishName: "",
    fishBodyShape: "",
    fishQuantity: 0,
    fishLength: 0,
    fishIdentification: "",
    saveType: "none",
    tankIdentification: "",
    tankName: "",
  });

  const logoPath = "././assets/images/koisklep-logo-min.png";

  useEffect(() => {
    (async () => {
      await store.create();
      setStore(store);

      isFirstStart = await store.get("isFirstStart");

      if (isFirstStart === null) {
        setInitialValues(store);
      }

      loadTankArrayFromStorage(store, setStoredTankEntries);
    })();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/shop">
              <Shop />
            </Route>
            <Route exact path="/calculator">
              <Calculator
                lang={lang}
                store={store}
                storedTankEntries={storedTankEntries}
                setStoredTankEntries={setStoredTankEntries}
                growthGoal={growthGoal}
                setGrowthGoal={setGrowthGoal}
                feedType={feedType}
                setFeedType={setFeedType}
                waterTemperature={waterTemperature}
                setWaterTemperature={setWaterTemperature}
              />
            </Route>
            <Route exact path="/myfish">
              <MyFish
                store={store}
                storedTankEntries={storedTankEntries}
                setStoredTankEntries={setStoredTankEntries}
                fishModalData={fishModalData}
                setFishModalData={setFishModalData}
                lang={lang}
              />
            </Route>
            <Route exact path="/more">
              <More store={store} lang={lang} />
            </Route>
            <Route exact path="/">
              <Redirect to="/calculator" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom" color="secondary">
            <IonTabButton tab="myfish" href="/myfish">
              <IonIcon icon={fish} />
              <IonLabel>{lang.text_menubar_fish}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="calculator" href="/calculator">
              <IonIcon icon={calculator} />
              <IonLabel>{lang.text_menubar_calc}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="calculator" href="/calculator">
              <IonImg className="logo" src={logoPath} />
            </IonTabButton>
            <IonTabButton tab="shop" href="/shop">
              <IonIcon icon={basket} />
              <IonLabel>{lang.text_menubar_shop}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="more" href="/more">
              <IonIcon icon={keypad} />
              <IonLabel>{lang.text_menubar_more}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
